/* Navbar.module.css */
.navWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: rgba(17, 24, 39, 0.95);
  backdrop-filter: blur(12px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.navbar {
  max-width: 1400px;
  margin: 0 auto;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

.leftSection {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.brand {
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 600;
  text-decoration: none;
  white-space: nowrap;
}

.brand:hover {
  color: #ffffff;
}

.nav {
  display: flex;
  gap: 1.5rem;
}

.navLink {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s ease;
}

.navLink:hover {
  color: #ffffff;
}

.searchSection {
  flex: 1;
  max-width: 600px;
}

.searchForm {
  display: flex;
  gap: 0.75rem;
}

.searchInput {
  flex: 1;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  padding: 0.75rem 1rem;
  color: white;
  font-size: 0.95rem;
  min-width: 0;
}

.searchInput:focus {
  outline: none;
  background-color: rgba(255, 255, 255, 0.15);
  border-color: rgba(255, 255, 255, 0.3);
}

.searchInput::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.buttonGroup {
  display: flex;
  gap: 0.5rem;
}

.searchButton {
  padding: 0.75rem 1.25rem;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.2s ease;
  white-space: nowrap;
  background: linear-gradient(to right, #4f46e5, #7c3aed);
  color: white;
}

.searchButton:hover {
  transform: translateY(-1px);
  opacity: 0.9;
}

@media (min-width: 1921px) {
  .navbar {
    max-width: 80%;
    padding: 1.5rem 4rem;
  }

  .brand {
    font-size: 2rem;
  }

  .nav {
    gap: 2.5rem;
  }

  .navLink {
    font-size: 1.2rem;
  }

  .searchInput {
    font-size: 1.1rem;
    padding: 1rem 1.5rem;
  }

  .searchButton {
    padding: 1rem 2rem;
    font-size: 1.1rem;
  }

  .searchSection {
    max-width: 800px;
  }
}

/* Mobile */
@media (max-width: 768px) {
  .navbar {
    padding: 1rem;
    flex-direction: column;
    gap: 1rem;
  }

  .leftSection {
    width: 100%;
    justify-content: space-between;
    gap: 1rem;
  }

  .nav {
    display: none;
  }

  .searchSection {
    width: 100%;
    max-width: 100%;
  }

  .searchForm {
    flex-direction: column;
    gap: 0.5rem;
  }

  .buttonGroup {
    width: 100%;
  }

  .searchButton {
    width: 100%;
  }
}