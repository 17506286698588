.footer {
  background-color: rgba(17, 24, 39, 0.95);
  backdrop-filter: blur(8px);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  padding: 2rem 0;
  margin-top: auto;
  width: 100%;
}

.container {
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
}

.content {
  display: flex;
  justify-content: flex-start;
  gap: 8rem;
  max-width: 1200px;
  margin: 0 auto;
}

.section {
  flex: 0 0 auto;
  max-width: 300px;
}

.section:first-child {
  max-width: 400px;
}

.brand {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1rem;
}

.brandName {
  font-size: 1.5rem;
  font-weight: 600;
}

.description {
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 1rem;
  line-height: 1.6;
  text-align: left;
}

.address {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
}

.links {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.link {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  transition: color 0.2s ease;
}

.link:hover {
  color: white;
}

.copyright {
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.9rem;
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

/* Wide screens */
@media (min-width: 1921px) {
  .container {
    max-width: 1800px;
    width: 80%;
  }

  .content {
    max-width: 1600px;
    gap: 8rem;
    justify-content: flex-start;
    margin-left: 0;
  }

  .section {
    flex: 0 0 auto;
    max-width: 350px;
  }

  .section:first-child {
    max-width: 500px;
  }

  .brandName {
    font-size: 1.8rem;
  }

  .description {
    font-size: 1.1rem;
  }

  .copyright {
    max-width: 1600px;
    text-align: left;
    padding-left: 0;
  }
}

/* Mobile */
@media (max-width: 768px) {
  .footer {
    padding: 2rem 0;
  }

  .container {
    padding: 0 1rem;
    width: 100%;
  }

  .content {
    flex-direction: column;
    gap: 2rem;
  }

  .section {
    max-width: 100%;
  }

  .description {
    white-space: normal;
  }
}

/* Tablet */
@media (min-width: 769px) and (max-width: 1024px) {
  .content {
    gap: 4rem;
  }

  .section {
    max-width: 250px;
  }

  .section:first-child {
    max-width: 300px;
  }
}