.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
}

.modal :global(.modal-dialog) {
  width: 90% !important;
  max-width: 600px !important;
  margin: 1.75rem auto !important;
  position: relative !important;
}

.modal :global(.modal-content) {
  background: #1f2937 !important;
  border-radius: 12px !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.5) !important;
  position: relative;
  width: 100%;
}

.modalHeader {
  background: #1f2937;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  flex-direction: row;
  justify-content: space-between;
}

.modalTitle {
  color: #e5e7eb;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
}

.closeButton {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: #e5e7eb;
  font-size: 1.5rem;
  opacity: 0.7;
  cursor: pointer;
  padding: 0.5rem;
  transition: opacity 0.2s ease;
  z-index: 1;
}

.closeButton:hover {
  opacity: 1;
}

.modalBody {
  background: #1f2937;
  padding:  1rem 1rem 2rem;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.infoRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0.375rem;
}

.infoText {
  color: #9ca3af;
  font-size: 0.875rem;
}

.currentBid {
  color: #93c5fd;
  font-weight: 600;
}

.formGroup {
  margin-bottom: 1.5rem;
}

.formLabel {
  color: #e5e7eb;
  font-weight: 500;
  margin-bottom: 0.5rem;
  display: block;
}

.formControl {
  background: rgba(255, 255, 255, 0.05) !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  border-radius: 0.375rem;
  box-sizing: border-box;
  -moz-appearance: textfield;
  color: #e5e7eb !important;
  transition: all 0.2s ease;
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 1rem;
}

.formControl:focus {
  background: rgba(255, 255, 255, 0.1) !important;
  border-color: rgba(255, 255, 255, 0.3) !important;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.3) !important;
  outline: none;
}

.checkboxGroup {
  margin-bottom: 1.5rem;
}

.checkbox {
  color: #e5e7eb;
  display: flex;
  align-items: flex-start; 
  gap: 0.5rem;
  line-height: 1.4; 
  max-width: 90%; 
}

.checkbox input {
  margin-top: 0.3rem; 
}

.submitButton {
  width: 100%;
  background: #4f46e5 !important;
  color: white !important;
  border: none !important;
  padding: 0.875rem !important;
  border-radius: 0.375rem;
  font-weight: 500;
  font-size: 1rem;
  transition: all 0.2s ease;
}

.submitButton:hover {
  background: #4338ca !important;
  transform: translateY(-1px);
}

.alert {
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
}

.success {
  background: rgba(16, 185, 129, 0.1);
  border: 1px solid rgba(16, 185, 129, 0.2);
  color: #6ee7b7;
}

.danger {
  background: rgba(239, 68, 68, 0.1);
  border: 1px solid rgba(239, 68, 68, 0.2);
  color: #fca5a5;
}

.modalSubtitle {
  color: #9ca3af;
  font-size: 0.875rem;
  margin: 0 0 1.5rem 0;
  text-align: left;
}

@media (min-width: 1921px) {
  .modal :global(.modal-dialog) {
    max-width: 800px !important;
  }

  .modalTitle {
    font-size: 2rem;
  }

  .formControl {
    padding: 1rem 1.25rem;
    font-size: 1.2rem;
  }

  .submitButton {
    padding: 1.25rem !important;
    font-size: 1.2rem;
  }
}

@media (max-width: 768px) {
  .modal :global(.modal-dialog) {
    width: 95% !important;
    margin: 1rem auto !important;
  }

  .modalBody {
    padding: 1rem;
  }

  .infoRow {
    flex-direction: column;
    gap: 0.5rem;
  }
}