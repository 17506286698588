.card {
  background: rgba(30, 41, 59, 0.7);
  border-radius: 12px;
  transition: all 0.3s ease;
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  min-height: 400px;
  display: flex;
  flex-direction: column;
}

.card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  background: rgba(30, 41, 59, 0.9);
}

.cardContent {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.title {
  color: #fff;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.details {
  flex-grow: 1;
  margin-bottom: 1.5rem;
}

.detailRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9rem;
}

.label {
  color: rgba(255, 255, 255, 0.6);
}

.value {
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
}

.priceSection {
  margin-bottom: 1.5rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.price {
  color: #10b981;
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

.bids {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
}

.buttonContainer {
  display: flex;
  gap: 0.75rem;
  margin-top: auto;
  padding-top: 1rem;
}

.button {
  flex: 1;
  padding: 0.75rem;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  transition: all 0.2s ease;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  cursor: pointer;
  font-size: 0.95rem;
}

.primaryButton {
  background: linear-gradient(to right, #4f46e5, #7c3aed);
}

.button:hover {
  transform: translateY(-1px);
}

.primaryButton:hover {
  background: linear-gradient(to right, #4338ca, #6d28d9);
}

@media (min-width: 1921px) {
  .card {
    min-height: 500px;
  }

  .title {
    font-size: 1.8rem;
  }

  .detailRow {
    font-size: 1.1rem;
  }

  .price {
    font-size: 1.4rem;
  }

  .button {
    padding: 1rem;
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .card {
    min-height: 350px;
  }

  .cardContent {
    padding: 1rem;
  }

  .buttonContainer {
    flex-direction: column;
  }
}