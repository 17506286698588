.container {
    padding: 2rem;
    min-height: calc(100vh - 140px);
    background-color: #111827;
  }
  
  .title {
    color: #fff;
    font-size: 2.5rem;
    font-weight: 600;
    padding-top: 2rem;
    margin-bottom: 2rem;
  }
  
  .biddingsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 2rem;
    padding: 1rem;
  }
  
  .biddingCard {
    background: rgba(30, 41, 59, 0.7);
    backdrop-filter: blur(8px);
    border-radius: 12px;
    padding: 1.5rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: transform 0.2s ease;
  }
  
  .biddingCard:hover {
    transform: translateY(-4px);
  }
  
  .cardHeader {
    margin-bottom: 1rem;
  }
  
  .gpuType {
    color: #fff;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  
  .serverName {
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.9rem;
  }
  
  .bidInfo {
    margin: 1rem 0;
  }
  
  .priceRow, .locationRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    padding: 0.5rem 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .label {
    color: rgba(255, 255, 255, 0.7);
  }
  
  .value {
    color: #fff;
    font-weight: 500;
  }
  
  .cardFooter {
    margin-top: 1.5rem;
    text-align: center;
  }
  
  .viewDetailsButton {
    background: linear-gradient(to right, #4f46e5, #7c3aed);
    color: white;
    text-decoration: none;
    padding: 0.75rem 1.5rem;
    border-radius: 6px;
    display: inline-block;
    transition: all 0.2s ease;
  }
  
  .viewDetailsButton:hover {
    background: linear-gradient(to right, #4338ca, #6d28d9);
    transform: translateY(-2px);
  }
  
  .emptyState {
    text-align: center;
    color: #fff;
    padding: 3rem;
  }
  
  .browseButton {
    display: inline-block;
    margin-top: 1rem;
    padding: 0.75rem 1.5rem;
    background: linear-gradient(to right, #4f46e5, #7c3aed);
    color: white;
    text-decoration: none;
    border-radius: 6px;
    transition: all 0.2s ease;
  }
  
  .browseButton:hover {
    background: linear-gradient(to right, #4338ca, #6d28d9);
    transform: translateY(-2px);
  }
  
  @media (min-width: 1921px) {
    .container {
      padding: 3rem;
    }
  
    .title {
      font-size: 3rem;
    }
  
    .biddingsGrid {
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
      gap: 3rem;
    }
  
    .biddingCard {
      padding: 2rem;
    }
  
    .gpuType {
      font-size: 1.5rem;
    }
  
    .viewDetailsButton {
      padding: 1rem 2rem;
      font-size: 1.2rem;
    }
  }
  
  @media (max-width: 768px) {
    .container {
      padding: 1rem;
    }
  
    .title {
      font-size: 2rem;
      padding-top: 1rem;
    }
  
    .biddingsGrid {
      grid-template-columns: 1fr;
      gap: 1rem;
      padding: 0.5rem;
    }
  
    .biddingCard {
      padding: 1rem;
    }
  
    .cardFooter {
      margin-top: 1rem;
    }
  }