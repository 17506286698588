.container {
    padding: 2rem;
    min-height: calc(100vh - 140px);
    background-color: #111827;
  }
  
  .title {
    color: #fff;
    font-size: 2.5rem;
    font-weight: 600;
    padding-top: 2rem;
    margin-bottom: 2rem;
  }
  
  .listingsWrapper {
    margin-top: 2rem;
  }
  
  .listingsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 2rem;
    padding: 1rem;
  }
  
  .cardWrapper {
    display: flex;
    flex-direction: column;
  }
  


  @media (min-width: 1921px) {
    .container {
      padding: 3rem;
    }
  
    .title {
      font-size: 3rem;
    }
  
    .listingsGrid {
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
      gap: 3rem;
    }
  }
  
  @media (max-width: 768px) {
    .container {
      padding: 1rem;
    }
  
    .title {
      font-size: 2rem;
      padding-top: 1rem;
    }
  
    .listingsGrid {
      grid-template-columns: 1fr;
      gap: 1rem;
      padding: 0.5rem;
    }
  }