.container {
  padding: 2rem;
  padding-top: 5rem;
  min-height: calc(100vh - 140px);
  background-color: #111827;
  color: #e5e7eb;
}

.header {
  display: flex;
  justify-content: flex-start;  /* Changed from space-between since bid button moved */
  align-items: center;
  margin-bottom: 2rem;
  margin-top: 1rem;
  padding: 1rem 0;
  width: 100%;
}

.backButton {
  background: rgba(255, 255, 255, 0.9) !important;
  border: none !important;
  padding: 0.5rem 1.5rem !important;
  transition: all 0.2s ease !important;
  color: #111827 !important;
  font-weight: 500 !important;
}

.backButton:hover {
  background: rgba(255, 255, 255, 1) !important;
  transform: translateX(-2px);
}

.bidButtonContainer {
  display: flex;
  justify-content: center;
  margin: 2rem auto;
  width: 100%;
}

.bidButton {
  background: linear-gradient(to right, #4f46e5, #7c3aed) !important;
  border: none !important;
  padding: 0.75rem 2rem !important;  /* Increased padding */
  font-size: 1.2rem !important;      /* Increased font size */
  font-weight: 500 !important;
  transition: all 0.2s ease !important;
  color: white !important;
}

.bidButton:hover {
  background: linear-gradient(to right, #4338ca, #6d28d9) !important;
  transform: translateY(-2px);
}

.title {
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 2rem;
  color: #fff;
  text-align: center;
}

.detailsContainer {
  background: rgba(30, 41, 59, 0.7);
  backdrop-filter: blur(8px);
  border-radius: 12px;
  padding: 2rem;
  margin-bottom: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.detailRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.detailRow:last-child {
  border-bottom: none;
}

.label {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  flex: 1;
  text-align: left;
}

.value {
  color: rgba(255, 255, 255, 0.9);
  font-weight: 600;
  text-align: right;
  flex: 2;
}

@media (min-width: 1921px) {
  .detailsContainer {
    max-width: 1200px;
    padding: 3rem;
  }

  .title {
    font-size: 3.5rem;
  }

  .detailRow {
    padding: 1.5rem;
  }

  .bidButton {
    padding: 0.75rem 2rem !important;
    font-size: 1.1rem !important;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 1rem;
  }

  .title {
    font-size: 2rem;
  }

  .detailsContainer {
    padding: 1rem;
  }

  .detailRow {
    flex-direction: column;
    gap: 0.5rem;
  }

  .header {
    flex-direction: row;
    gap: 1rem;
    padding: 0.5rem 0;
  }

  .backButton,
  .bidButton {
    padding: 0.4rem 1rem !important;
    font-size: 0.9rem !important;
  }

  .bidButtonContainer {
    margin: 1.5rem auto;
  }
}